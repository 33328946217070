.steps-wrapper {
    height: 100%;
}

.steps-body {
    border: none;
}

.steps-body.footer {
    height: calc(100vh - 142px) !important;
}

.rct-node-icon {
    color: #717171;
}

#awesome-pdf-viewer>.main {
    height: calc(85vh - 185px) !important;
}

#awesome-pdf-viewer>.main>.right-panel {
    position: relative;
}

#esign-aweosme-pdf-viewer>.main {
    height: calc(100vh - 240px) !important;
}

.review-main-container {
    display: flex;
}

.review-left-panel {
    overflow: auto;
    background-color: white;
    margin: auto;
}

.summary-step-container {
    height: 100%;
    min-height: 400px;
    padding: 0 15px;
}

.completed-summary-step-container {
    height: 100vh !important;
    min-height: 500px;
}

.completed-summary-step-container .content-right {
    overflow: auto;
}

.content-left {
    /* padding-top: 20px;*/
    height: 100%;
}

@media (max-width: 768px) {
    .content-left {
        padding-right: 15px !important;
    }
}

.pay-content-top {
    padding-top: 20px;
}

.content-right {
    /*margin-left: 25px;*/
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.payment-step-container {
    display: flex;
    height: 97%;
    margin-top: 10px;
}

.tax-payment-container {
    background-color: white;
    height: 100%;
}

.payment-refund-container {
    height: 48%;
    margin-right: 0px !important;
    padding-right: 0px !important;
}

.payment-info-modal-title {
    width: 100%;
}

.step-layout {
    height: 100%;
}

.step-layout.with-footer {
    height: calc(100vh - 112px) !important;
}



.step-layout.left {
    float: left;
}

.step-layout.center {
    margin: auto;
}

.step-layout.right {
    float: right;
}

.step-layout-header {
    padding: 10px;
    font-size: 20px;
    height: 50px;
    background-color: white;
    font-weight: bolder;
}

.step-layout-footer,
.step-layout-top-bar {
    margin: auto;
    background-color: white;
}

.step-layout-footer {
    border-top: 1px solid #dbdbdb;
}

.step-layout-top-bar {
    padding: 14px !important;
    height: auto;
    /*max-height: 200px;*/
    min-height: 50px;
    overflow: auto;
}

.pay-step {
    display: inline-flex;
}

.step-layout-left-panel-header,
.step-layout-body-header {
    font-size: 20px;
    min-height: 65px;
    padding: 10px 15px 0px 15px;
    font-weight: bolder;
    height: auto;
    background-color: white;
    line-height: inherit;
}

.step-layout-body-header>.sub-title {
    font-weight: bold;
    font-size: 14px;
    height: 100px;
    color: #747474;
}

.step-layout-left-panel-body {
    padding: 0px 15px 0px 0px;
}

.step-layout-body {
    padding: 0px 15px 0px 15px;
}

.step-layout-left-panel-body,
.step-layout-body {
    height: calc(100%);
    overflow-y: auto;
    overflow-x: hidden;
}

.step-layout-body.without-header {
    height: 100% !important;
}

.step-layout-body {
    width: 100%;
}


.step-layout-content {
    min-height: 400px;
    height: 100%;
}

.step-layout-header+.step-layout-content {
    height: calc(100% - 50px);
}


.step-layout-body-container {
    margin: 0px auto 0px auto;
}

.step-layout-left-panel {
    background-color: white;
}

.step-layout-left-panel,
.step-layout-body-container {
    border: 1px solid #eeeeee;
    border-radius: 2px;
    height: 100%;
}

.step-layout-body.white {
    background-color: white;
}

.step-layout-body.transparent {
    background-color: transparent;
}

.custom-button {
    background-color: #5ffc5f1c;
    border: 2px solid #83c786;
    height: 65px;
    border-radius: 5px;
    display: flex;
    padding: 8px;
    cursor: pointer;
}

.custom-button.left {
    float: left;
}

.custom-button.center {
    margin: auto;
}

.custom-button.xl {
    width: 250px;
}

.custom-button.lg {
    width: 225px;
}

.custom-button.md {
    width: 175px;
}

.custom-button.sm {
    width: 125px;
}

.custom-button.right {
    float: right;
}


.custom-button:hover {
    background-color: #9fff9f6b;
}

.custom-button-icon-container {
    width: 42px;
}

.custom-button-text-container {
    width: 75%;
    overflow: hidden;
    user-select: none;
    color: #0274bb;
    font-weight: bold;
    font-size: 14px !important;
    margin: auto auto auto 10px;
    font-family: sans-serif, 'Roboto', 'Poppins';
}

.download-document.with-long-text .custom-button-text-container {
    width: 100%;
}


/* Custom button large */


.custom-large-button {
    background-color: #a3f7a31c;
    border: 1.3px solid #83c786;
    height: 130px;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
    width: 250px;
}

.custom-large-button.left {
    float: left;
}

.custom-large-button.center {
    margin: auto;
}

.custom-large-button.right {
    float: right;
}


.custom-large-button:hover {
    background-color: #cafdca6b;
}

.custom-large-button-icon-container {
    width: 100%;
    height: 50%;
    margin: auto;
    margin-top: 15px;
}

.custom-large-button-icon-container>svg {
    width: 100%;
    height: 100%;
}


.custom-large-button-text-container {
    overflow: hidden;
    user-select: none;
    color: #0274bb;
    font-weight: bolder;
    font-size: 14px !important;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.large-button-footer-text {
    font-weight: bold;
}

/* Custom button large end */
.upload-documents-container {
    height: calc(100% - 25px);
}


.download-documents-area {
    height: 100px;
    color: #747474;
}

.upload-documents-title {
    margin: 20px auto;
}

.upload-documents-title img {
    width: 20px;
}

.upload-documents-title strong {
    margin-left: 10px;
}

.text-blue {
    color: #0274bb;
}

.company-info>.company-address {
    height: 65%;
    min-height: 100px;
}

.download-documents-area>.downlod-button-container {
    margin: 10px 0px 0px 0px;
}

.company-info>.company-fax {
    height: 35%;
    min-height: 100px;
}

#signed-document-upload.with-border {
    border: 3px dashed #9ed4a0;
}

.upload-area {
    padding: 65px 0px 0px 0px;
    text-align: center;
    cursor: pointer;
}

.upload-area.minimized {
    border: 3px dashed #9ed4a0;
    margin-bottom: 20px;
    padding: 40px 0px 0px 0px !important;
}

.uploaded-files-list {
    height: calc(100% - 120px);
    overflow: auto;
    padding: 10px;
}

.uploaded-files-list.minimized {
    border: 2px solid #9ed4a0;
}

.file-list-container {
    display: inline;
    height: 100%;
}

.file-list-container>p {
    margin-bottom: 2rem;
}

.file-list-item {
    border-top: 1px solid #cbd2d9;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 2rem;
}

.file-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.file-list-item .icon {
    font-size: 2.4rem;
    margin-right: 1rem;
}

.manual-sign-uploads-table-action-btn {
    color: #8BC34A;
    font-weight: bold;
}



ul.task-items {
    padding: 0px !important;
}

ul.task-items li.item {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 2px 0;
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}

ul.task-items li.item.type1 .task .icon {
    cursor: pointer;
}

ul.task-items li.item.type1 .task .icon i {
    color: red;
}


ul.task-items li.item .task {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ul.task-items li.item .task .icon {
    width: 33px;
    height: 31px;
    border-radius: 20px;
    border-color: grey;
    border: 0px solid grey;
    padding: 5px 0px 0px 9px;
}

ul.task-items li.item .task .name {
    margin-left: 10px;
    width: 100%;
    height: 25px;
    max-width: 0px;
}

.uploaded-date-mobile {
    margin-left: 10px;
    font-size: smaller;
}


ul.task-items li.item .dates {
    text-align: center;
    width: 15%;
    min-width: 90px;
}

ul.task-items li.item .dates .bar,
ul.task-items li.item .priority .bar {
    height: 25px;
    border-radius: 15px;
}

ul.task-items li.item .priority {
    text-align: center;
    cursor: pointer;
    width: 15%;
}

.drop-area-highlight {
    background: #fff;
    box-shadow: 0 0 5px 10px rgba(149, 255, 26, 0.22);
}

.btn-ssr {
    background-color: white;
    color: #017cff;
    border-color: #28a745;
    border-radius: 20px;
    height: 35px;
    border: 1px solid #28a745;
    font-weight: bold;
    padding: 0px 15px 0px 15px;
}

.step-icon {
    height: 100%;
    width: 100%;
}

/* Pay Step - START*/

.pay-content-right {
    height: auto;
    background-color: white;
    overflow: auto;
    border-top: 1px solid #eeeeee;
    height: 100%;
}

.pay-content-left {
    min-width: 260px;
    height: 100%;
}


.voucher-detail-container {
    height: 100%;
}

.voucher-detail-container .top-div {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    height: 90px;
    width: 100%;
}

.voucher-dropdown {
    display: inline-flex;
    margin-top: 14px;
}

.voucher-dropdown .voucher-checkbox {
    padding-right: 5px;
    height: 30px;
}

.voucher-dropdown .dropdown {
    display: inline;
}

.voucher-dropdown .dropdown-toggle {
    width: 70px;
    text-align: center;
}

.voucher-dropdown .scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-y: scroll;
    width: 68px;
}

.voucher-dropdown .show.dropdown {
    display: inline !important;
}

.voucher-list-div {
    border: 2px solid #ccc;
    border-radius: 16px;
    padding: 10px;
    position: relative;
    width: 100%;
    overflow-x: auto;
    height: 80%;
    margin: inherit;
}

.voucher-list-div .section-heading,
.pay-left-panel-list .section-heading {
    color: #0973ba;
    text-align: center;
    margin-bottom: 3%;
    font-weight: 600;
}

.pay-left-panel-list {
    padding: 30px 20px 0px 20px;
    height: calc(100% - 75px);
}

.pay-screen-voucher {
    padding: 0;
    list-style-type: none;
}

section>.section-title {
    color: #0973ba;
    font-size: 15px;
    color: #0973ba;
}

.pay-left-panel {
    font-weight: bold;
    font-size: 14px;
}

.pay-screen-right-pointer {
    position: relative;
    background-color: #0973ba;
    /*background: #0973ba;*/
    height: 45px;
    line-height: 40px;
    margin-bottom: 20px;
    vertical-align: middle;
    color: white !important;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
    width: 100% !important;
}

.pay-content-right .pay-display-box {
    border: 2px solid #ccc;
    min-height: 235px;
    width: 175px;
    border-radius: 3px;
    float: left;
    margin: 14px;
    position: relative;
    text-align: center;
}

.pay-content-right .pay-box {
    border: 2px solid #ccc;
    height: 235px;
    width: 175px;
    border-radius: 5px;
    float: left;
    margin: 25px;
}

.pay-content-right .pay-display-box.done {
    /* background: url("/Content/img/icons/check-mark.png") no-repeat top right; */
    background-size: 55px;
}

.pay-content-right .pay-display-box .pay-date {
    border: 1.5px solid #9E9E9E;
    border-radius: 3px;
    width: 115px;
    position: relative;
    top: -15px;
    left: -10px;
    background-color: #EDF7E6;
    padding: 2px;
    text-align: center;
}

.pay-content-right .pay-display-box .pay-icon {
    text-align: center;
    margin: 0 auto;
    height: 40%;
}

.pay-content-right .pay-display-box .pay-fee-name {
    text-align: center;
}

.pay-content-right .pay-display-box .pay-fee-amount {
    text-align: center;
    margin: 0 auto;
    font-weight: 600;
}

.pay-content-right .pay-display-box .pay-button {
    /*margin: 15px 0px 0px 35px;*/
    position: relative;
}

/*    .pay-content-right .pay-display-box .pay-button .btn-secondary {
        min-width: 0;
        border: 2px solid #8bc656;
        background-color: #f3f9ee;
        color: black !important;
        min-width: 100px !important;
        border-radius: 5rem !important;
    }*/
/*
.content-right .pay-display-box .pay-button .btn-secondary:hover {
    background-color: #e8f4dd;
}*/

.pay-content-right .pay-display-box .pay-button .info {
    position: absolute;
    top: 6px;
    right: 9px;
}

/*Payment information popup custom classed*/
.pay-box {
    border: 2px solid #D0D0D0;
    border-radius: 5px;
    background-color: #FAFAFA;
    margin: 10px 0px 0px -9px;
    width: 220px;
    /*color: #0973ba;*/
    font-weight: bold;
    min-height: 64px;
    /*position: absolute;*/
    bottom: 30px;
    min-height: 315px;
    /*width: 46%;*/
    margin: auto;
    padding: 10px 10px;
}

.content-title {
    font-weight: bold;
    font-size: 13px;
    color: #0973ba;
    border-bottom: 1px dashed #E0E0E0;
    height: 40px;
}

.spacer {
    line-height: 36px;
}

.spacer1 {
    line-height: 20px;
}

.download-singlevoucher {
    text-align: right;
    margin-right: 3px;
    margin-top: -25px;
    padding-bottom: 5px;
    color: #0973ba;
    cursor: pointer;
}

.modal-body .pay-box .action-box-sm .box-image {
    float: left;
    width: 60px;
    padding-left: 10px;
    padding-top: 6px;
    margin-right: 10px;
}

.clearfix {
    clear: both;
}

.tColor {
    color: #0973ba;
    font-weight: bold
}

.modal-body .pay-box .action-box-sm p.pay-spacer {
    margin-top: 7%;
}

.modal-body .pay-box .action-box-sm.online-icon .box-image {
    padding-top: 10px;
}

.action-box-sm {
    min-height: 60px;
    max-width: 95%;
}

.pay-info-icon {
    cursor: pointer;
    border: 2px solid #8bc656;
    border-radius: 7px;
    margin-top: 68px;
    margin-right: 7px;
    max-width: 210px;
}

.pay-info-text {
    min-height: 140px;
    margin-top: 10px;
}

.pay-filing-instruction-link {
    float: right;
    margin-right: 10px;
    font-family: sans-serif, 'Roboto', 'Poppins';
    margin-bottom: 10px;
}

.icon-expanded {
    cursor: pointer;
    border: 2px solid #8bc656;
    border-radius: 7px;
    margin-top: 20%;
    margin-right: 7px;
    max-width: 210px;
}

.icon-voucher-expanded {
    cursor: pointer;
    border: 2px solid #8bc656;
    border-radius: 7px;
    margin-top: 28%;
    margin-right: 7px;
    max-width: 210px;
}

.icon-payment-expanded {
    cursor: pointer;
    border: 2px solid #8bc656;
    border-radius: 7px;
    margin-top: 15%;
    margin-left: 52%;
    max-width: 210px;
}

.pay-table {
    line-height: 36px;
}

.pay-icon {
    text-align: center;
    margin: 0 auto;
}

.pay-icon .authority-image {
    padding: 10px;
}

.pay-online-title {
    font-weight: bold;
    font-size: 13px;
    color: #0973ba;
    border-bottom: 1px dashed #E0E0E0;
    height: 58px;
}

.pay-info-online-text {
    min-height: 122px;
    margin-top: 10px;
}

.pay-box-manual {
    border: 2px solid #D0D0D0;
    border-radius: 5px;
    background-color: #FAFAFA;
    margin: 10px 0px 0px -9px;
    font-weight: bold;
    min-height: 64px;
    bottom: 30px;
    height: 315px;
    margin: auto;
    padding: 10px 10px;
}

.img-responsive {
    height: 50px;
    display: initial;
}

.pay-fee-name {
    text-align: left;
}

.btn-pay-online {
    -webkit-transition: .15s ease-in-out !important;
    transition: .15s ease-in-out !important;
    padding: 6px;
    font-size: 13px !important;
    margin-left: 6px;
}

.large-text {
    font-size: 22px;
    margin-bottom: 10px;
}

.larger {
    font-size: 14px;
}

td.amount {
    padding-right: 10px;
}

.custom-model {
    cursor: pointer;
    border-radius: 5px;
    position: absolute;
    right: 20px;
    margin-right: 20px;
    margin-top: -27px;
    width: 193px;
    background-color: rgb(243, 249, 238);
    border: 1px solid lightgreen;
}

.btn-radius {
    border-radius: 20px !important;
}

/*Payment information popup custom classed*/
/* Pay Step - END*/
.k1download-button {
    background-color: #5ffc5f1c;
    border: 2px solid #83c786;
    height: 50px;
    border-radius: 5px;
    width: 180px;
    display: flex;
    padding: 5px;
    cursor: pointer;
}

.k1download-button-icon-container {
    width: 25%;
}

.k1download-button-text-container {
    width: 75%;
    overflow: hidden;
    user-select: none;
    color: #0274bb;
    font-weight: bolder;
    margin: auto 0px;
}

.download-helper {
    position: absolute;
}

.download-button-helper {
    position: absolute;
    right: 20px;
    border-radius: 5px;
}

.distributeK1Tbl ul.pagination {
    float: right;
}

.distributeManualTbl ul.pagination {
    float: right;
}

.manualsign-body {
    height: calc(100vh - 150px) !important;
    border: none;
}

.manualsign-footer {
    padding: 12px;
    height: 58px;
}

.myDownload-delete {
    margin-left: -130%;
    margin-top: 80%;
    color: indianred !important;
    cursor: pointer;
}

.pay-body {
    border: none;
    justify-content: center;
    min-height: 600px;
}

.k1distribution-body {
    border: none;
    justify-content: center;
    min-height: 600px;
    padding: 50px 0px 0px 0px;
}

.kba-wrapper {
    height: Calc(100% - 60px);
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}


.react-checkbox-tree {
    display: block;
}

.download-footer {
    border-radius: 5px;
    position: relative;
    margin: auto;
    background: #FFF;
    padding: 15px;
}

.btn.btn-primary.rounded,
.btn.btn-secondary.rounded,
.btn.btn-default.rounded {
    color: white !important;
    min-width: 100px !important;
    border-radius: 5rem !important;
    border-color: rgb(159, 227, 80) !important;
}

.btn.btn-default.rounded {
    color: black !important;
}



.btn.btn-primary.rounded i.fa.fa-save,
.btn.btn-primary.rounded i.fa.fa-times {
    padding-right: 5px;
}

.steps-navigation-item-main {
    border: none !important;
    background-color: none !important;
}


.btn-secondary {
    min-width: 0 !important;
    border: 2px solid #8bc656 !important;
    background-color: #f3f9ee !important;
    color: black !important;
    min-width: 100px !important;
    border-radius: 5rem !important;
}


.btn-secondary:hover {
    background-color: #e8f4dd;
    border-color: #9fe350;
}

.btn-secondary:active {
    background-color: #e8f4dd !important;
    border-color: #9fe350 !important;
}

.svg-shadow {
    filter: drop-shadow(3px 3px 3px #DADADA);
}

.state-shadow {
    filter: drop-shadow(1px 1px 2px #DADADA);
}

table.voucher {
    min-width: 100%;
    border: 1px solid #C0C0C0;
}

.image-col {
    width: 90px;
    padding: 10px;
}

.indent {
    padding-left: 20px;
    /* max-width: 40px;*/
    display: table-cell;
}

.lined {
    border-top: 1px dotted #C0C0C0;
    padding-bottom: 10px;
}

.link-text {
    font-family: sans-serif, 'Roboto', 'Poppins';
    margin-top: 15px;
}

.download-list-icon {
    margin-left: 7px;
}

.dowload-list-first {
    border-top: 1px solid #ccc;
}

.my-downloads {
    margin-left: 10px;
    border-left: 1px solid;
    padding-left: 10px;
}

.sub-title {
    font-weight: bold;
    font-size: 14px;
    height: 100%;
    color: #747474;
}

.attachment-padding {
    padding: 0px 14px !important;
}

.ribbon.ribbon--green {
    background-color: #ffff00;
}

.navigation-start-control-text {
    color: #fa6c09;
}

.ribbon.ribbon--green:after {
    border-top: 1.5rem solid #ffff00;
}


.content-disabled {
    cursor: not-allowed !important;
}

.shepherd-element.shepherd-element-left.shepherd-enabled,
.shepherd-element.shepherd-element-right.shepherd-enabled {
    top: -6px !important;
}

.shepherd-element.shepherd-element-left.top-portion-element.shepherd-enabled,
.shepherd-element.shepherd-element-right.top-portion-element.shepherd-enabled {
    top: 0px !important;
}



li span.rct-text span.rct-node-clickable {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    width: 200px !important;
}

.rct-node-clickable:focus {
    outline: 0;
    background: transparent;
}

.file-node-selected {
    outline: 0;
    background: rgba(51, 51, 204, 0.3);
}

.document-control {
    min-height: 10px !important;
    z-index: 10;
}

.attachment-tab-container th,
.attachment-tab-container td {
    border-top: none !important;
}

.attachment-tab-container th:focus,
.attachment-tab-container td:focus {
    outline: none !important;
}

.second-signerinfo {
    display: inline-flex;
    width: 100%;
}

.emailSection {
    width: 100%;
}

.mobileSection {
    width: 100%;
}


div#ddlCountryCode {
    width: 150px;
}

.myaccount-text.phonenumber {
    display: inline-flex;
    width: 100%;
}

.pay-container {
    display: inline-flex;
    padding-top: 10px;
}

.custombutton-left .custom-button.right.lg {
    margin-left: 5px;
    width: 200px;
}

.custombutton-left .custom-button-text-container {
    margin: auto;
}

.phoneNumberFullWidth {
    width: 100%;
}

.overflow-a {
    overflow: auto;
}

.spouse-detail-update-button {
    background-color: #14d13f;
    border: #14d13f;
    min-width: 100px;
}

.spouse-detail-update-pop-up-fields {
    display: "flex";
    justify-content: "center";
    align-items: "center";
    padding-top: 0.5em;
}

.spouse-detail-update-pop-up-fields .leftmargin55 {
    margin-left: 55px !important;
}

.asterisk-error{
    color: #cc4a43;
}

.company-info > .company-address {
    min-height: 100px;
    color: black;
    font-size: 16px;
    font-style: normal;
    line-height: 140%;
}

.company-info > .company-address > .title, .company-info > .company-fax > .title {
    font-size: 16px;
    font-style: normal;
    line-height: 140%;
}

.company-info > .company-fax {
    min-height: 35px;
    color: black;
    font-size: 16px;
    font-style: normal;
    line-height: 140%;
}

.clienthublinkdisabled {
    opacity: 0.5;
    cursor: not-allowed;
}