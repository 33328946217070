:root {
    --control-background-color: #f4f9f0;
    --control-border-color: #88c656;
    --border-color-gray: #d5d5d5;
}

html {
    height: 100%;
}

body {
    background-color: #3d3e3f !important;
    font-family: sans-serif, 'Poppins';
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    height: 100%;
    margin: 0;
}

h1 {
    font-family: sans-serif, 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 26.4px;
}

h3 {
    font-family: sans-serif, 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 15.4px;
}

p {
    font-family: sans-serif, 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 20px;
}

blockquote {
    font-family: sans-serif, 'Poppins';
    font-size: 21px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 30px;
}

pre {
    font-family: sans-serif, 'Poppins';
    font-size: 13px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 18.5714px;
}

#overlay-loader[data-show="true"] {
    display: block !important;
}

#overlay-loader {
    background: #ffffff;
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 25%;
    opacity: .80;
}

#overlay-loader>.spinner {
    margin: 0 auto;
    height: 40px;
    width: 40px;
    animation: rotate 0.8s infinite linear;
    border: 3px solid #0973ba;
    /*border: 3px solid var(--headerBgColor);*/
    border-right-color: transparent;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    position: fixed;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


#app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*min-height: 100vh;*/
    height: 100%;
    background-color: #f0f0f0;
}

#app>div {
    min-height: 100%;
}

.app-header {
    height: 52px;
    background-color: #0973ba;
    background-color: var(--headerBgColor);
    padding: 6px;
    z-index: 2;
    position: relative;
    display: flex;
}

.popover {
    border: 1px solid #0973ba;
    /*border: 1px solid var(--headerBgColor);*/
}

.popover-header {
    background-color: #0973ba;
    /*background-color: var(--headerBgColor) !important;*/
    color: white;
    /*color: var(--headerForeColor);*/
    font-size: 12px;
    font-weight: bold;
    margin: 1px;
}

.headerForeColor {
    color: white;
    color: var(--headerForeColor) !important;
}

.ddl-icon {
    margin-right: 10px;
    color: #0973ba;
    /*color: var(--headerBgColor);*/
    width: 15.75px;
}

.btn-icon {
    margin-right: 10px;
}

.account-menu a {
    color: #0973ba !important;
    /*color: var(--headerBgColor) !important;*/
}

.account-menu a:hover {
    color: #0973ba !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}

.btn-primary {
    background-color: #88c656 !important;
    border: 1px solid #88c656;
    border-color: #88c656 !important;
    /*background-color: var(--bottonBgColor) !important;
	border: 1px solid var(--bottonBorderColor);
	border-color: var(--bottonBorderColor) !important;*/
}

.btn-primary,
.btn-light,
.btn-info {
    border-radius: 50px !important;
    min-width: 120px;
    font-weight: 500;
    font-size: 14px;
}

.btn-primary:hover {
    color: #fff;
    background-color: #88c656 !important;
    border-color: #88c656 !important;
    /*background-color: var(--bottonBorderColor) !important;
		border-color: var(--bottonBgColor) !important;*/
}

.completebtn {
    background-color: #5C8001;
    border: 1px solid #5C8001;
    padding: 8px 16px;
    width: 139px;
    height: 48px;
    right: 98px;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    border-radius: 2px;
}

.returnToHomeBtn {
    background-color: #5C8001;
    border: 1px solid #5C8001;
    padding: 8px 16px;
    height: 48px;
    max-width: 150px;
    right: 98px;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    border-radius: 2px;
}
/*-------------Font Face -------------*/

@font-face {
    font-family: 'Journal';
    src: url('../fonts/journal.eot');
    src: url('../fonts/journal.eot?#iefix') format('embedded-opentype'), url('../fonts/journal.woff') format('woff'), url('../fonts/journal.ttf') format('truetype'), url('../fonts/journal.svg#JournalRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.no-padding-left {
    padding-left: 0px !important;
}

.no-padding-right {
    padding-right: 0px !important;
}

.no-padding-top {
    padding-top: 0px !important;
}

.no-padding-bottom {
    padding-bottom: 0px !important;
}

.no-padding {
    padding: 0px 0px 0px 0px !important;
}


.no-margin {
    margin: 0 !important;
}

.width-100 {
    width: 100%;
}

.width-98 {
    width: 98%;
}

.font-style-italic {
    font-style: italic
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-left-50 {
    margin-left: 50px !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}

.padding-left-6 {
    padding-left: 6px;
}

.height-100 {
    height: 100%
}

.popover {
    border: 1px solid #88c656 !important;
    /*border: 1px solid var(--bottonBgColor) !important;*/
}

._loading_overlay_wrapper {
    min-height: 100vh;
}

._loading_overlay_overlay {
    margin-top: -52px;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d6cece;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #777;
}

.scroller {
    scrollbar-width: thin;
}

/* Initial application loading icon - START */
.loader,
.loader:before,
.loader:after {
    background: #ffffff;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}

.loader {
    color: #ffffff;
    text-indent: -9999em;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: '';
}

.loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader:after {
    left: 1.5em;
}

@-webkit-keyframes load1 {

    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@keyframes load1 {

    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

/* Initial application loading icon - END */

/* Layout - Start */

header .company-name {
    color: white;
    color: var(--headerForeColor);
    font-size: 24px;
    margin-left: 10px;
}

.logo-img {
    height: 40px;
}

.content-wrapper {
    background-color: #F0F0F0;
    height: calc(100% - 52px);
}


.header-left-container {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: auto;
    padding-left: 6%;
}

.header-right-container {
    margin-left: auto;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    padding-right: 6%;
    min-width: 175px;
}

.header-account {
    margin-right: 15px;
}

.header-mydownload,
.header-contact-info,
.header-logout,
.header-account,
.header-home-info,
.header-taxYear,
.header-taxReturn {
    float: right;
    color: white;
    color: var(--headerForeColor);
    cursor: pointer;
    margin-left: 15px;
    padding: 12px 0px 0px 5px;
}

.header-contact-info a {
    color: white;
    color: var(--headerForeColor);
}

.header-contact-info a svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 5px;
}

.header-contact-info:hover,
.header-logout:hover,
.header-home-info:hover {
    text-decoration: underline;
}

.header-taxYear a svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 5px;
}

.header-home-info a svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 5px;
}

.header-account a svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 5px;
}

.header-taxYear:hover {
    cursor: text;
}

.mail {
    font-size: 14px;
    color: #88c656;
    font-weight: bold;
}

.mail a:hover {
    cursor: pointer;
}

.account-menu,
.taxYear-menu,
.taxReturn-menu {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
}

.header-account a::after

/*, .header-taxYear a::after, .header-taxReturn a::after*/
    {
    content: "";
    width: 0px;
    height: 1px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid #ffffff;
    border-top: 7px solid var(--headerForeColor);
    position: absolute;
    margin-left: 5px;
    margin-top: 8px;
    z-index: 100;
}

.sidebarIconToggle,
#sidebarMenu {
    display: none;
}

#sidebarMenu {
    height: 100%;
    position: fixed;
    left: 0;
    width: 225px;
    margin-top: 30px;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;
    /*background: linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%);*/
    background-color: #0973ba;
    /*background-color: var(--headerBgColor);*/
    overflow: scroll;
    z-index: 999;
}

.sidebarMenuInner {
    margin: 0;
    padding: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.sidebarMenuInner li {
    list-style: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.sidebarMenuInner li span {
    display: block;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.50);
}

.sidebarMenuInner li a {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

input[type="checkbox"]:checked~#sidebarMenu {
    transform: translateX(0);
}

#openSidebarMenu {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
}

.sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    top: 10px;
    left: 15px;
    height: 22px;
    width: 22px;
}

.spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
}

.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

.diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
}

.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

input[type=checkbox]:checked~.sidebarIconToggle>.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
}

input[type=checkbox]:checked~.sidebarIconToggle>.diagonal.part-1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}

input[type=checkbox]:checked~.sidebarIconToggle>.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
}


.show {
    display: block !important;
}

.sidebar {
    width: 225px !important;
    min-height: calc(100vh - 42px);
    user-select: none;
}

.navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1.5rem !important;
}

.navbar-nav {
    float: left;
    margin: 0;
}

.navbar-nav>li {
    float: left;
    border-top: 1px solid #fff;
}

.navbar-nav>li:last-child {
    border-bottom: 1px solid #fff;
}

.dropup,
.dropright,
.layout-dropdown,
.dropleft {
    position: relative;
}

.sidebar .nav-item .nav-link {
    display: block;
    text-align: left;
    padding: 1rem;
    width: 225px;
}

.sidebar .nav-item .nav-link {
    color: #fff;
}

.sidebar .nav-item.layout-dropdown .layout-dropdown-toggle::after {
    display: block;
}


navbar-nav .nav-item.layout-dropdown .layout-dropdown-toggle span {
    margin: 10px;
}

.sidebar .nav-item .nav-link span {
    font-size: 1.5rem;
    display: inline;
}

.nav-link span {
    color: #fff;
    text-shadow: none;
}



.layout-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.layout-dropdown-menu {
    box-shadow: none;
    position: static !important;
    margin-left: 30px;
    top: 0;
    background-color: transparent;
    border: none;
}

.navbar-nav .layout-dropdown-menu {
    position: static;
    float: none;
}

.sidebar .nav-item .layout-dropdown-menu {
    -webkit-transform: none !important;
    transform: none !important;
    left: calc(50px + 0.5rem) !important;
    margin: 0;
}

.layout-dropdown-menu .layout-dropdown-item {
    display: block;
    width: 100%;
    padding: 0.50rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #fff;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 14px;
}

.layout-dropdown-menu.show {
    padding-top: 0px;
}

a.active.layout-dropdown-item {
    text-decoration: underline;
}

/* Layout - END */



/* Summary Page Start */

.content-top,
.content-left,
.content-wrapper-full {
    margin-left: 8%;
}



.content-right,
.content-wrapper-full {
    padding-right: 125px;
    margin-top: 0px !important;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    height: 98%;
}

.content-left>div {
    padding: 10px 0px 0px 10px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
}



.estimated-voucher-container {
    height: 48%;
    margin-top: 3%;
    margin-right: 0px !important;
    padding-right: 0px !important;
}

.payment-refund-container .title,
.estimated-voucher-container .title,
.tax-payment-container .title,
.payment-instruction-container .title {
    margin-bottom: 5px;
    margin-left: 0px;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: normal;
    color: #000000;
}

.payment-refund-container .body,
.estimated-voucher-container .body {
    padding: 0px 10px;
    overflow: auto;
    height: 85%;
}



.sub-section .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    padding: inherit;
}

.bold {
    color: #000;
}

.fllft {
    float: left;
}

.green {
    color: #86bf51;
}

.red {
    color: #e00d0d;
}

.flrt {
    float: right;
}

.semibold {
    font-weight: 600;
}

.currencytext {
    text-align: right;
    padding-right: 0px;
    /*font-family: monospace;*/
    font-family: sans-serif, 'Roboto', 'Poppins';
}

.content-left .section-title {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #0973ba;
    clear: both;
}

.content-left .preSelect:first-of-type {
    clear: both;
    margin: 20px 1px;
}

.preSelect {
    clear: both;
    margin: 40px 1px;
}

.sub-section {
    clear: both;
}

.total-amount-green {
    border-top: 1px solid #000;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    width: 40% !important;
}

.total-amount-red {
    border-top: 1px solid #000;
    font-size: 15px;
    font-weight: 700;
    width: 40% !important;
}

.content-left .sub-section div {
    width: 50%;
    position: relative;
}

.content-left .sub-section .authority {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #000000;
}

.content-left .sub-section .amount {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
}

.welcome-container {
    padding-top: 50px;
}

.content-right .welcome {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-70 {
    margin-top: 70px;
}

.margin-left-1 {
    margin-left: 1%;
}

.content-right .welcome-intro {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin: 10% 0px 10px 0px;
}

.step-img-btn {
    text-align: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.step-img-btn>div {
    display: inline-block;
    float: left;
    margin-bottom: 25px;
}

.step-img-btn>div>svg {
    height: 92px;
    cursor: pointer;
}

.step-img-btn .img-caption {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #0973ba;
    margin-top: 10px;
    cursor: pointer;
}

.summary-begin-btn {
    width: 260px;
    height: 58px;
}

.summary-start-step-container {
    text-align: center;
    width: 100%;
}

.summary-start-step-container button {
    font-size: 14px;
    font-weight: bold;
    border-radius: 50px !important;
    margin: 6% 0%;
}

.icon---summary-review-documents {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.icon---summary-sign-documents {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.icon---distribute-k1s {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.icon---summary-make-payments {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.center-loading-text {
    position: absolute;
    font-size: 1.5em;
    top: 40vh;
    left: 40vw;
    z-index: 99;
    background-color: #fff;
}

/*Summary Page End*/
@media (max-width: 800px) {

    .content-wrapper-full .content-select-sign-buttons {
        display: block !important;
        margin-top: 10px;
        height: calc(100% - 80px) !important;
    }

    .app-header {
        height: 36px;
        padding: 6px;
    }

    header .company-name {
        font-size: 18px;
    }

    .logo-img {
        height: 24px;
    }

    .welcome-page {
        padding-left: 10% !important;
        padding-right: 20% !important;
    }

    .welcome-page-container {
        padding: 10%;
    }

    .header-contact-info {
        padding: 2px;
    }

    .header-left-container {
        padding-left: 40px;
    }

    .header-right-container {
        padding-right: 10px;
    }

    .header-contact-info,
    .header-logout {
        padding: 2px 6px;
        margin-left: 0;
    }

    .ssn-page,
    .otp-page {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .ssn-page-container,
    .otp-page-container {
        padding-bottom: 75px !important;
    }

    .access-code-expiry-info {
        margin-left: 0px !important;
    }

    .sidebarIconToggle,
    #sidebarMenu {
        display: inherit !important;
    }

    .header-account,
    .header-taxYear,
    .header-taxReturn {
        display: none;
        padding: 0px !important;
        margin: 0px !important;
    }

    .content-top,
    .content-left,
    .content-right,
    .pay-content-left,
    pay-content-top .content-wrapper-full {
        overflow-x: hidden;
    }

    .payment-refund-container,
    .estimated-voucher-container {}

    .steps-wrapper {
        /*overflow: auto;*/
        /* Commented because of  bug 41444*/
    }

    .imageBorderEsign {
        margin: 24px !important;
    }

    .imageBorderManualSignText {
        margin: 9px !important;
    }

    .information-popup {
        left: 20% !important;
    }
}

/* Sign Page - Start */
.imageBorderEsignSelect {
    border: 2px solid #88c656;
    border-radius: 16px;
    opacity: 0.7;
    transition: 0.3s;
    background-color: #d8d8d8 !important;
    width: 216px;
    height: 141px;
    text-align: center;
    display: block;
    margin: 0 auto;
}

.imageBorderEsignSelect:hover {
    opacity: 1;
}

.imageBorderManualSign {
    margin: 24px 0px 0px 15px;
}

.tab {
    display: inline-block;
    margin-left: 0px;
}

.tab:hover {
    cursor: pointer;
    text-decoration: underline;
}

.imageTaxpayerSigning {
    margin: 8% 0% 0% 0%;
}



.signPageDesc {
    width: 100%;
}

.font-weight-normal-bold {
    font-weight: 600;
    padding-right: 5px;
}

.font-weight-bold {
    font-weight: bold;
}

/* Sign Page - End */
@media (min-width: 800px) and (max-width: 1024px) {
    .content-left {
        margin-left: 3%;
    }

    .steps-wrapper {
        overflow: auto;
    }

    .step-img-btn>div.col-lg-4 {
        width: 33%;
    }

    .step-img-btn>div.col-lg-3 {
        width: 25%;
    }

    .information-popup {
        left: 30% !important;
    }
}

/* Esign page Start */
.content-wrapper-full {
    border-radius: 5px;
    position: relative;
    margin-left: 10%;
    margin-right: 10%;
    padding: 15px;
}

.content-wrapper-full>div {
    background-color: #fff;
}

.content-wrapper-full .content-header {
    font-size: large;
    font-weight: 500;
    padding: 20px;
}

.content-wrapper-full .content-paragraph {
    padding: 0px 20px 10px;
}

.content-wrapper-full input[type=checkbox] {
    display: inline;
}

.content-wrapper-full input[type=text] {
    border: 2px solid var(--border-color-gray);
    border-radius: 4px;
    height: 30px;
    width: 200px;
}

.content-wrapper-full .scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
    display: inline !important;
    width: 100%
}

.content-wrapper-full .dropdown-toggle.btn.btn-success {
    color: var(--control-border-color);
    background-color: var(--control-background-color);
    border-color: var(--control-border-color);
    border-radius: 4px !important;
    width: 100px;
    margin-bottom: 5px;
    text-align: left;
}

.content-wrapper-full .dropdown-toggle.btn.btn-success::after {
    position: absolute;
    right: 10px;
    top: 4px;
}

.content-wrapper-full .content-select-sign-buttons {
    height: calc(100% - 62px);
    display: inline-flex;
    align-items: center;
}

.content-wrapper-full .esign-div {
    /* width: 50%;*/
    text-align: center;
    display: block;
    /*padding-left: 21%;*/
}

.content-wrapper-full .esign-div .imageBorderEsignSelect .imageBorderEsign {
    color: #1a8fbf;
    /*margin: 65px;*/
}

.content-wrapper-full .content-select-sign-buttons .esign-div .signPageSpan1 {
    position: relative;
    display: inline-flex;
    font-weight: bold;
}

.content-wrapper-full .manusign-div {
    /*width: 50%;*/
    text-align: center;
    display: block;
}

.content-wrapper-full .manusign-div .imageBorderEsignSelect .imageManualSigner {
    margin: 0%;
    width: 93%;
}

.content-wrapper-full .manusign-div .imageBorderEsignSelect .imageBorderManualSignText {
    color: #1a8fbf;
    margin: 54px;
}

.content-wrapper-full .manusign-div .signPageSpan2 {
    position: relative;
    font-weight: bold;
    /*margin: 1% 0% 0% 1%;*/
}

.padding-full-20 {
    padding: 20px;
}

.margin-right-15p {
    margin-right: 15%;
}

.YearMonthDay {
    display: inline;
    padding-left: 0px;
}

.YearMonthDay .select-control {
    display: inline;
}

.YearMonthDay .select-control .show.dropdown {
    display: inline !important;
}

.YearMonthDay .select-control .dropdown {
    display: inline;
}

.dropdown-menu {
    min-width: auto !important;
}

.consent-checkbox {
    padding: 20px;
}

.spouse-header {
    color: #1a8fbf;
    padding: 20px;
}

.signing-page-main-container {
    width: 100%;
}

.kba-questions {
    margin-top: 20px;
    font-weight: bold;
}

.react-checkbox-tree:not(.rct-native-display) .rct-checkbox {
    display: none;
}

.btn-bottom-right {
    bottom: 0;
    right: 0;
    position: absolute;
    margin: 10px;
}

.black-border-line {
    border: 1px solid var(--border-color-gray);
}

.dib {
    display: inline-block;
}

.width-50 {
    width: 50%;
}

.width-40 {
    width: 40%;
}

.width-60 {
    width: 60%;
}

.height-44 {
    height: 44px !important
}

/* Esign page End */

#summary-review {
    height: 100%;
    overflow: auto;
}

.payment-refund-print {
    float: right;
    font-size: larger;
    color: #0973ba;
    cursor: pointer;
    margin-right: 10px;
}

#preparer_message,
.step-layout-body-container.signing-completed-wrapper {
    height: calc(100vh - 200px);
}

.preparerMessage {
    margin-top: 10px;
    border: 1px solid #e9e9e9;
    height: calc(100% - 55px) !important;
    overflow-y: auto;
    padding: 20px;
    position: relative;
    font-weight: 500;
}


.preparerMessageContainer {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
}


.preparerMessagePrint {
    float: right;
    color: #0973ba;
    font-size: x-large;
    cursor: pointer;
}

.reviewTab {
    float: left;
    width: 15%;
    height: 88%;
    border-right: 2px solid darkgray;
    background-color: white;
}

.reviewContainerBody {
    height: 88% !important;
    background-color: #e0e0e0;
    border-right: 2px solid;
}

#review-tab-navbar {
    margin: 20px 0px;
    color: #88c656;
    font-weight: 700;
    width: auto;
    padding-left: 18px;
    font-size: larger
}


.review-left-panel {
    margin: 0;
    padding: 0;
    overflow: unset !important;
}

.review-left-pdf-viewer {
    padding: 0;
}

.reviewContainer {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 5px;
    width: 96%;
    height: 88%;
    margin-left: 20px;
}

.right-pointer {
    position: relative;
    background-color: #0973ba;
    /*background: var(--headerBgColor);*/
    height: 45px;
    line-height: 40px;
    margin-bottom: 20px;
    vertical-align: middle;
    color: white !important;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
    width: 85% !important;
}

.arrow-right:before {
    content: "";
    position: absolute;
    right: -30px;
    top: -2px;
    border-top: 26px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 25px solid transparent;
    width: 31px;
}

.cursor-pointer {
    cursor: pointer;
}

.arrow-right:after {
    content: "";
    position: absolute;
    right: -31px;
    top: 0px;
    border-top: 24px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 23px solid #0973ba;
    /*	border-left: 23px solid var(--headerBgColor);*/
    width: 32px;
    color: white;
    z-index: 9;
}

#document-review {
    margin-left: 25px !important;
}

.review-container {
    height: 100%;
    padding: 0;
}

.review-container-body {
    display: flex;
    border-bottom: 2px solid darkgray;
    background: white;
    height: calc(100% - 55px);
}

.review-container-title {
    height: 55px;
    border-bottom: 2px solid darkgray;
    padding: 12px 0px 12px 30px;
    font-weight: bold;
    font-size: x-large;
    background: white;
}

.attachment-viewer {
    padding-top: 20px;
    padding-left: 20px;
}

.attachment-file-viewer {
    cursor: pointer;
    color: #4CAF50;
    font-weight: 700;
}

#attachment-table {
    font-size: larger;
}

.attachment-ZipBtn {
    margin-top: 20px;
}

.buttonDownload {
    display: inline-block;
    position: relative;
    padding: 10px 25px;
    background-color: #0973ba;
    /*background-color: var(--headerBgColor);*/
    color: white;
    font-family: sans-serif;
    text-decoration: none;
    font-size: 0.9em;
    text-align: center;
    cursor: pointer
}

/* keyframes for the download icon anim */
@keyframes downloadArrow {

    /* 0% and 0.001% keyframes used as a hackish way of having the button frozen on a nice looking frame by default */
    0% {
        margin-top: -7px;
        opacity: 1;
    }

    0.001% {
        margin-top: -15px;
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        margin-top: 0;
        opacity: 0;
    }
}

.colorBlue {
    color: #1a8fbf;
}

@media(min-width:315px) and (max-width:421px) {
    .esign-div {
        margin-right: 10%;
        padding: 0% 0% 0% 0%
    }

    body {
        overflow: auto;
    }

    .information-popup {
        left: 0 !important;
    }
}

.myaccount .modal-header {
    padding: 15px;
}

.myaccount .modal-title {
    color: #0973ba;
    /*color: var(--headerBgColor);*/
    font-size: 14px;
    font-weight: bold;
}

.myaccount .modal-body {
    position: relative;
    padding: 15px;
    padding-left: 20px;
}

.myaccount .modal-footer {
    padding: 15px;
    text-align: right;
}

.myaccount .modal-content {
    width: 130%;
    margin-left: -50px;
}

.myaccount-text {
    padding: 10px;
    font-size: 12px;
}

.myaccount-changenumber {
    padding-right: 20px;
    padding-left: 4px;
}

.myaccount-countrycode {
    padding-right: 0px;
    padding-left: 20px;
}

.accesscode {
    z-index: 1061;
}

.accesscode.footer button {
    margin: 0 5px;
}

.pending {
    border: 3px dashed #e6b800;
    min-height: 50px;
    max-height: 75px;
    overflow: auto;
    overflow-y: hidden;
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.alert-warning {
    color: #9a6d4b;
    background-color: #fcf8e3;
    /* border-color: #faebcc !important; */
}

.imageBorder {
    border: 1px solid #88c656;
    border-radius: 4px;
    opacity: 0.7;
    transition: 0.3s;
    background-color: #FAFAFA !important;
    cursor: pointer;
}

.imageBorder:hover {
    opacity: 1;
}

.send-reminder {
    float: right;
    color: #88c656;
}

.send-reminder:hover {
    text-decoration: underline;
    cursor: pointer;
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.imageSizing {
    width: 50%;
    padding: 4%;
    margin: 10%;
}

.complete-wizard-welcome-intro {
    display: inline-flex !important;
    margin: 0px 30px 15px 0px;
    padding: 0% 0% 0% 18%;
    width: 100%;
}

.margin-left-5 {
    margin-left: 5%;
}

/*Custom Checkbox START*/
/* Customize the label (the container) */
.consent-checkbox {
    display: block;
    position: relative;
    padding-left: 50px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.consent-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 16px;
    width: 16px;
    top: 20px;
    left: 20px;
    z-index: 99;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border: 1px solid var(--control-border-color);
    border-radius: 4px !important;
}

/* On mouse-over, add a grey background color */
.consent-checkbox input:hover~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.consent-checkbox input:checked~.checkmark {
    background-color: var(--control-background-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.consent-checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.consent-checkbox .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #9fe350;
    border: solid var(--control-border-color);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*Custom Checkbox END*/


/*K1 distribution start*/

.row-selected {
    background-color: #caedff !important;
}

.btn-white {
    background: #FAFAFA;
    border: 1px #e0e0e0 solid;
    color: #303641;
    margin-right: 2px;
    margin-bottom: 2px;
}

.btn-white:hover {
    background: #ebebeb;
}

.padT10 {
    padding-top: 10px;
}

.padT5 {
    padding-top: 5px;
}

.marL10 {
    margin-left: 10px;
}

.content-wrapper-2 {
    /*margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
    max-width: 80% !important;*/
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
}


.content-wrapper-2 .content-select-k1-buttons {
    margin: 10% 0% 0% 0%;
    width: 100%;
    padding: 0% 15% 0% 0%;
    display: inline-flex;
    height: 220px;
}

.content-wrapper-2 .content-select-k1-buttons .k1-electronic-div {
    width: 50%;
    padding-left: 19%;
}

.content-wrapper-2 .content-select-k1-buttons .k1-electronic-div .imageBorderK1Electronic {
    color: #1a8fbf;
    margin: 55px;
}

.content-wrapper-2 .content-select-k1-buttons .k1-electronic-div .k1PageSpan1 {
    margin: 1% 0% 0% 1%;
    position: fixed;
    display: inline-flex;
}

.content-wrapper-2 .content-select-k1-buttons .k1-manual-div {
    width: 27%;
    margin: 0% 0% 0% 20%;
    padding: 0% 0% 0% 4%;
}

.content-wrapper-2 .content-select-k1-buttons .k1-manual-div .imageBorderK1Manual {
    color: #1a8fbf;
    margin: 45px;
}

.content-wrapper-2 .content-select-k1-buttons .k1-manual-div .k1PageSpan2 {
    position: fixed;
    margin: 1% 0% 0% 2%;
}

.imageBorderK1Select {
    border: 2px solid #88c656;
    border-radius: 16px;
    opacity: 0.7;
    transition: 0.3s;
    background-color: #d8d8d8 !important;
}

.imageBorderK1Select:hover {
    opacity: 1;
}


.k1PartnerPopup .modal-header {
    padding: 15px;
}

.k1PartnerPopup .modal-title {
    color: #0973ba;
    /*	color: var(--headerBgColor);*/
    font-size: 14px;
    font-weight: bold;
}

.k1PartnerPopup .modal-body {
    position: relative;
    padding: 15px;
    padding-left: 20px;
}




/* Pay Step - START */

.pay-content-left>div {
    /*padding: 10px;*/
    background-color: #fff;
    border-radius: 5px;
    position: relative;
}

/*.pay-content-right > div {
    padding: 13px 20px 0px 20px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
}*/

.pay-content-top>div {
    padding: 10px 21px 10px 20px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    /*width: 93%;*/
}

.pay-content-left .tax-payment-container {
    height: 100%;
    padding: 19px 21px 38px 20px;
}

#pay-tab-navbar {
    margin: 20px 0px;
    color: #88c656;
    font-weight: 700;
    width: auto;
    padding-left: 10px;
    font-size: 14px;
}

.pay-right-pointer {
    position: relative;
    background-color: #0973ba;
    /*background: var(--headerBgColor);*/
    height: 40px;
    line-height: 40px;
    margin-bottom: 20px;
    vertical-align: middle;
    color: white !important;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
    width: 100% !important;
    border-radius: 6px;
}

.pay-arrow-right:before {
    content: "";
    position: absolute;
    right: -30px;
    top: -2px;
    border-top: 26px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 25px solid transparent;
    width: 31px;
}


.pay-arrow-right:after {
    content: "";
    position: absolute;
    right: -28px;
    top: 0px;
    border-top: 24px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 23px solid #0973ba;
    /*border-left: 23px solid var(--headerBgColor);*/
    width: 32px;
    color: white;
    z-index: 9;
}

.pay-currencytext {
    text-align: right;
    padding-right: 15px;
    font-family: sans-serif, 'Roboto', 'Poppins';
}

/* Pay Step - END */

.k1PartnerPopup .modal-footer {
    padding: 15px;
    text-align: right;
}

.k1PartnerPopup-text {
    padding: 10px;
    font-size: 12px;
}

.displayFlex {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.displayFlex3 {
    flex: 3;
}

@media (min-width:311px) and (max-width: 800px) {
    .imageBorderK1Electronic {
        margin: 5px !important;
    }

    .mydownload-popup-icon {
        margin-top: -8px;
    }

    .imageBorderK1Select {
        width: 100px;
    }

    .imageBorderK1Manual {
        margin: 5px !important;
    }

    .k1PageSpan1 {
        margin: -18%;
        position: absolute !important;
        display: inline-table;
        padding: 5% 0% 0% 3%;
    }

    .k1PageSpan2 {
        position: absolute !important;
        margin: -17%;
        padding: 5% 0% 0% 10%;
        display: inline-table;
    }

    .displayFlex {
        flex-direction: column;
    }

    .distributeK1Tbl table,
    .distributeK1Tbl thead,
    .distributeK1Tbl tbody,
    .distributeK1Tbl th,
    .distributeK1Tbl td,
    .distributeK1Tbl tr {
        display: block;
    }

    .distributeK1Tbl thead tr :not(:first-child) {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .distributeK1Tbl tr {
        border: 1px solid #ccc;
    }

    .distributeK1Tbl td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .distributeK1Tbl td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }

    .distributeK1Tbl td:nth-of-type(1):before {
        content: "";
    }

    .distributeK1Tbl td:nth-of-type(3):before {
        content: "Name";
    }

    .distributeK1Tbl td:nth-of-type(4):before {
        content: "Email";
    }

    .distributeK1Tbl td:nth-of-type(5):before {
        content: "Mobile";
    }

    .distributeK1Tbl td:nth-of-type(6):before {
        content: "Status";
    }

    .distributeK1Tbl td:nth-of-type(7):before {
        content: "Action";
    }
}

/*K1 distribution end*/

/* Download Page - START */

.inline-block-container {
    display: inline-block;
    vertical-align: top;
}

.download-title {
    font-size: 20px;
    font-weight: 600;
}

.download-list .title {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 10px;
}

.download-list ul {
    list-style: none;
    padding: 0px;
    margin-top: 10px;
}

.download-list ul li {
    color: #88c656;
    border-bottom: 1px dotted #ccc;
    font-weight: bold;
    cursor: pointer;
    padding-top: 7px;
}

.download-list ul li:active {
    background-color: #0973ba;
    color: #0973BA;
}

.download-list ul li:hover {
    background-color: #f3f9ee;
}

.download-container {
    min-height: 72vh;
    margin-top: 50px;
}


.padding-top-20 {
    padding-top: 20px !important;
}

.download-list ul li .download-image,
.download-list ul li .info,
.download-all-body ul li .download-image {
    float: right;
    padding: 1px;
    max-height: 16px;
    max-height: 16px;
    margin-right: 5px;
}

.download-footer button {
    font-size: 14px;
    font-weight: bold;
    border-radius: 50px !important;
}

.download-all-body ul {
    list-style: none;
    padding: 0px;
    margin-top: 10px;
    width: 100%;
}

.download-all-body ul li {
    color: #88c656;
    font-weight: bold;
    cursor: pointer;
    padding: 4px 0px;
    margin-bottom: 2px;
}

.download-all-body ul li:active {
    background-color: #0973ba;
    color: #fff;
}

.download-all-body ul li:hover {
    background-color: #f3f9ee;
}

/* Download Page - END */
.information-popup {
    position: absolute;
    top: 45%;
    left: 40%;
}

/*Screen share START*/
.vertical-center {
    margin: 0;
    height: 5em;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-40%, -220%);
    transform: translate(-40%, -220%);
}

.vertical-center .stop {
    height: 5em;
    width: 5em;
}

.vertical-center .stop.disabled {
    fill-opacity: 0.5;
}

.screen.partner {
    width: 80vw;
    border: 1px solid black;
    box-shadow: 2px 2px 3px black;
    margin: 1vw 10vw;
    /*right: 10px;
    position: absolute;*/
}

/*Screen share END*/

.ellipses {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 315px;
}

.phoneNumeberTextBox {
    font-size: 12px;
    height: 38px;
    border-radius: 4px !important;
    width: 70%;
    margin-left: 5px;
}

.phoneNumberSelect {
    padding-right: 4px;
    font-size: 12px;
}

.height-35 {
    height: 35px !important;
}

.divMailId {
    padding-top: 2px;
    padding-right: 0px;
}

.inputMailId {
    font-size: 12px;
    height: 37px;
    margin-top: -2px;
}

.divPhoneNumber {
    padding-left: 0px;
    padding-top: 2px;
    padding-right: 0px;
}

.labelMobileNumber {
    padding-left: 8px;
    font-weight: bold;
    font-size: 13px;
}

.height-38 {
    height: 38px !important;
}

/*Tutorial START*/
.tutorial-header {
    font-size: 16px;
    color: #0973ba;
    /*color: var(--headerBgColor);*/
    font-weight: 500;
}

.tutorial-body {
    margin: 12px 0px;
}

.tutorial-checkbox {
    display: block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.tutorial-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 16px;
    width: 16px;
    top: 16px;
    z-index: 99;
}

/* Create a custom checkbox */
.tutorial-checkmark {
    position: absolute;
    top: 16px;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border: 1px solid var(--control-border-color);
    border-radius: 4px !important;
}

/* On mouse-over, add a grey background color */
.tutorial-checkbox input:hover~.tutorial-checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.tutorial-checkbox input:checked~.tutorial-checkmark {
    background-color: var(--control-background-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.tutorial-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.tutorial-checkbox input:checked~.tutorial-checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.tutorial-checkbox .tutorial-checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #9fe350;
    border: solid var(--control-border-color);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.tutorial-checkbox-label {
    padding-left: 25px;
    margin-top: 15px;
}

/*Tutorial End*/

.attachment-tab-container {
    max-height: calc(100% - 180px);
    /*overflow-y: auto;
    overflow-x: hidden;*/
}

.attachment-download-btn-container {
    position: absolute;
    border: 0 !important;
}

.attachment-tab-container .react-bs-container-body {
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;
}

.invalid-container {
    background-color: #F0F0F0;
    height: 100%;
}

.invalid-header {
    background-color: #0973ba;
    height: 52px;
    padding: 6px;
}

.error-page {
    padding-left: 16% !important;
    padding-right: 16% !important;
    padding-top: 30px !important;
    height: 90vh;
    overflow: auto;
}

.error-page-container {
    border-radius: 6px;
    padding: 22% 10%;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
}

.error-page-container .message {
    font-size: 22px;
    text-align: center;
}


.icon---completed-signature {
    width: 13px;
    height: 14px;
    object-fit: contain;
}


.div-chip-list {
    align-items: center;
    border: 2px solid #d4d5d6;
    padding: 4px;
    max-height: 100px;
    width: 100%;
    display: flex;
    border-radius: 4px;
}

.chip-input {
    height: 25px;
    padding: 0 3px;
    box-sizing: border-box;
    font: inherit;
    border: none;
    color: #565656;
    outline: none;
    width: 100%;
}

.chip {
    border: 1px solid #d4d5d6;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 3px 0;
    background: #007bff;
    color: aliceblue;
}

#ccRecipient-modal {
    max-width: 700px;
}

@media (min-width: 800px) {
    #ccRecipient-modal .modal-content {
        min-width: 100% !important;
    }
}

@media (max-width: 800px) {
    #ccRecipient-modal {
        max-width: 600px;
    }
}

.cc-title {
    border: 1px solid #d4d5d6;
    padding: 5px;
    margin-top: 11px;
    text-decoration: underline;
}

.ssr-default-email {
    color: #0000ff;
    text-decoration: underline;
}

#ccRecipient-modal {
    margin-left: auto;
}

#ccRecipient-modal .modal-content .modal-header {
    height: 40px !important;
    background: #0973ba;
    padding-bottom: 45px !important;
}

#ccRecipient-modal .modal-content .modal-body {
    max-height: 540px;
    overflow-y: scroll;
}

.input-document-list {
    width: 17px;
    height: 17px;
}

.email-document-btn {
    border-radius: 18px !important;
    color: #fff;
}

.ccRecipient-input-titles {
    border: 2px solid #d4d5d6;
    height: 40px;
    margin: 6px 0px;
    background: lightgray;
    display: flex;
}

.ccRecipient-title-highlight {
    font-weight: bold;
    font-size: 15px;
    padding: 8px;
}

.float-right {
    float: right;
}

.cc-entered-email {
    padding-left: 30px;
}

.cc-entered-num {
    padding-left: 17px;
}

#closeCCRecipientPopover {
    opacity: 1;
    color: black;
    font-weight: 600;
    margin-left: 54%;
    margin-top: 3%;
}

#closeCCRecipientPopover .popover-header {
    background-color: #f2f2f2 !important;
    color: black;
    font-size: 13px;
}

.ccRecipientPopover-confirm-btn {
    border-radius: 22px !important;
    background-color: #f2f2f2 !important;
    border: 1px solid #f2f2f2;
    width: 100%;
    padding: 2px;
    margin-top: 10px;
}

#ccdownload_main {
    margin: auto;
    margin-top: 3%;
}

.ccdownload-content {
    padding: 15px;
    max-height: 500px;
    overflow-y: scroll;
}

.ccdownload-content ul {
    margin: 0px;
    padding: 0px;
}

.ccdownload-modal {
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    background: #ffffff;
}

.download-foo-btn {
    background: #f1f1f1;
    padding: 10px;
    border-radius: 0px 0px 5px 5px;
    -webkit-border-radius: 0px 0px 5px 5px;
    -moz-border-radius: 0px 0px 5px 5px;
    text-align: right;
}

.ccdownload-list {
    list-style-type: none;
    padding: 10px 0px 10px 44px;
    color: #88c656;
    font-weight: 600;
    cursor: pointer;
}

.cc-details-container {
    border-bottom: 1px solid #dee2e6;
    margin: 0px -77px;
}

.cc-remove-recipient {
    font-size: 17px;
    color: #dc3545;
    cursor: pointer;
    margin-top: 9px;
    margin-left: 13px;
}

.cc-add-recipient {
    font-size: 16px;
    cursor: pointer;
    color: green;
    margin: 0px 7px 6px 15px;
}

.cc-choose-docs {
    padding: 6px 6px 9px 6px;
    font-weight: 600;
}

div[id^=ccRecipientCountryCode] {
    width: 95px;
}

input[id^=ccMobile] {
    border: 2px solid #d4d5d6;
}

/*Completed Summary Page Style Starts here*/
.button---download-documents {
    width: 180px !important;
    height: 128px !important;
    object-fit: contain;
}

.button---signed-documents {
    width: 180px !important;
    height: 128px !important;
    object-fit: contain;
}

.button---tax-payments {
    width: 180px !important;
    height: 128px !important;
    object-fit: contain;
}

.button---Distribute-K1 {
    width: 180px !important;
    height: 128px !important;
    object-fit: contain;
}

/*Completed Summary Page Style Ends here*/

/*Add Voucher Payemnt style starts here*/
.padTLR {
    padding: 10px 50px 0px 50px
}

.voucherPayementLinkContainer {
    margin-top: 9px;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 9px;
}

.voucherPayementLink {
    color: #007bff !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}

.input-dollar {
    position: relative;
}

.input-dollar.left input {
    padding-left: 18px;
}

.input-dollar:before {
    position: absolute;
    top: 0;
    content: "$";
}

.input-dollar.left:before {
    left: 5px;
}

.input-group {
    width: 100%;
}

@media (min-width: 768px) {
    .input-group {
        width: auto;
    }
}

.input-group.date>input[type=text] {
    z-index: 0 !important;
}

.input-group-addon {
    padding: 9px;
    font-size: inherit;
    font-weight: normal;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 0px !important;
}

.btn-paid {
    min-width: 0 !important;
    border: 2px solid #89c05a !important;
    background-color: #89c05a !important;
    color: white !important;
    min-width: 70px !important;
    border-radius: 6px !important;
    box-shadow: none !important;
}

.voucher-paid-icon {
    position: absolute;
    top: -5%;
    left: 76%;
    color: #88c656;
    padding: 2px;
    text-align: center;
    background-color: #ffffff;
    font-size: 16px;
}

.voucher-pay-datepicker .react-datepicker__close-icon {
    background-color: #eeeeee !important;
    padding: 7px !important;
}

.voucher-pay-datepicker .react-datepicker__close-icon::after {
    background-color: #eeeeee !important;
    color: #333 !important;
    font-size: 20px !important;
}

.voucher-pay-datepicker .react-datepicker-wrapper {
    width: 83%;
}

.inputTextAllignRight {
    text-align: right;
    font-size: inherit;
}

.compulsory-star {
    font-size: 7px;
    color: #c50505;
    position: relative;
    bottom: 5px;
    left: 5px;
}

.calenderStyling {
    position: absolute;
    z-index: 1;
    left: 83%;
    font-size: 15px !important;
}

.input-fontSize {
    font-size: inherit;
}

.pointer-Stylling {
    cursor: default !important;
}

/*Add Voucher Payemnt style ends here*/

.download-document {
    width: 270px !important;
    height: 40px !important;
    margin-top: -5px;
}

.mce-statusbar {
    display: none !important;
}

.delegatee-input-field {
    align-items: center;
    border: 3px solid #d4d5d6;
    padding: 4px;
    max-height: 100px;
    width: 100%;
    display: flex;
    border-radius: 4px;
}

.width100 {
    width: 100% !important;
}

.delegatee-modal-title {
    font-size: 15px;
    word-spacing: 0.1rem;
    margin-bottom: 18px;
    font-weight: 600;
}

#delegatee-signer-country-code div[class$=-control],
.delegatee-number-field {
    border: 3px solid #d4d5d6;
    border-radius: 4px;
    height: 20px;
}

.font600 {
    font-weight: 600;
}

.center-align {
    justify-content: center;
}

.remarksTextArea {
    border: solid 2px #21a9e1;
    min-height: 100px;
    width: 100%;
    margin: 3px 0px 3px 0px;
    font-size: inherit;
}

.update-delegatee-icon {
    cursor: pointer;
}

.step-sign-complete {
    height: 100%;
    border: 2px solid rgb(9, 115, 186);
    border-radius: 50%;
}

.complete-icon {
    color: rgb(139, 198, 86);
    margin-top: 6px;
    font-size: 33px;
}

@media (max-width: 740px) {
    .complete-icon {
        margin-top: 4px;
        font-size: 14px;
    }
}

div[title="Complete"][class~="active-step"] .step-sign-complete .complete-icon {
    margin-top: 0px;
    transform: translate(6%, 35%);
}

.delegatee-kba-consent-wrapper {
    border-radius: 5px;
    position: relative;
    margin-left: 10%;
    margin-right: 10%;
    padding: 15px;
}

.delegatee-kba-consent-wrapper .step-layout-body-header {
    padding-left: 20px !important;
}

.delegatee-kba-consent-wrapper>div {
    background-color: #fff;
}

.delegatee-kba-consent-wrapper .esign-consent {
    padding-left: 8px;
}

.delegatee-kba-consent-wrapper .content-header {
    font-size: large;
    font-weight: 500;
    padding: 20px;
}

.delegatee-kba-consent-wrapper .content-paragraph {
    padding: 0px 0px 10px;
}

.delegatee-kba-consent-wrapper input[type=checkbox] {
    display: inline;
}

.delegatee-kba-consent-wrapper input[type=text] {
    border: 2px solid var(--control-border-color) !important;
    background-color: var(--control-background-color);
    border-color: var(--control-border-color);
    width: 80% !important;
    text-align: left;
    height: 34px !important;
    border-radius: 4px;
}

.delegatee-kba-consent-wrapper .scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
    display: inline !important;
}

.delegatee-kba-consent-wrapper .dropdown-toggle.btn.btn-success {
    color: var(--control-border-color);
    background-color: var(--control-background-color);
    border: 2px solid var(--control-border-color) !important;
    border-radius: 4px !important;
    width: 82px;
    margin-bottom: 5px;
    text-align: left;
}

.delegatee-kba-consent-wrapper .dropdown-toggle.btn.btn-success::after {
    position: absolute;
    right: 10px;
    top: 4px;
}

#delegatee-dob {
    display: inherit !important;
}

#delegatee-dob .select-control {
    padding: 0px 11px 0px 0px !important;
}

.delegatee-dob-dropdownselector {
    width: 70px !important;
}

.delegatee-kba-consent-wrapper .consent-checkbox {
    padding: 10px 10px 3px 30px !important;
}

.delegatee-kba-consent-wrapper .consent-checkbox input {
    left: 0px !important;
    top: 9px !important;
}

.delegatee-kba-consent-wrapper .checkmark {
    left: 0px !important;
    top: 10px !important;
}

.steps-navigation-container {
    justify-content: center;
}

@media (max-width: 768px) {
    #app>div {
        height: 100%;
    }

    .content-wrapper {
        height: calc(100% - 36px);
    }

    .content-wrapper .steps-wrapper {
        height: 100% !important;
    }

    .steps-body>.step {
        height: 100%;
    }

    .signing-page-main-container,
    .pdf-preview-container,
    #esign-aweosme-pdf-viewer {
        height: 100%;
    }

    #esign-aweosme-pdf-viewer>div.main {
        height: calc(100% - 45px) !important;
    }

    .steps-body>.step {
        overflow: auto !important;
    }
}



/* cover page Css*/

.fa-home:before {
    content: "\f015";
}


.cover-page {
    width: 75%;
    margin: auto;
    max-width: 500px;
    margin-top: 15vh;
}


.cover-page-container {
    border: 2px solid #88c656;
    border: 2px solid #2bcb15;
    max-height: 76vh;
    border-radius: 6px;
    padding: 50px;
    background-color: #ffffff;
    z-index: 2;
    cursor: pointer;
    color: black;
    border-top-right-radius: 20px 30px;
    border-bottom-right-radius: 20px 30px;
    min-height: 500px;
}


div .cover-page-container:after {
    border: solid transparent;
    height: 102%;
    border-width: 20px;
    top: -1%;
    left: Calc(100% - 13px);
    content: " ";
    position: absolute;
    pointer-events: none;
    border-left-color: inherit;
    border-radius: 8px 0px 0px 8px;
    margin-left: 0px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(0deg);
    z-index: -1;
    box-shadow: 10px;
}

.welcome-page-navigator {
    position: absolute;
    top: 65px;
    right: -94px;
    background-color: #88c656;
    background-color: #2bcb15;
    font-size: 12px;
    padding: 4px 16px;
    transform: rotate(90deg);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    z-index: 0;
    width: 140px;
    text-align: center;
    border: 2px solid;
    border-color: inherit;
    border-bottom: 0 !important;
    font-weight: bold;
}

.welcome-page-navigator a {
    color: white;
}

.welcome-page-navigator a:hover {
    text-decoration: none;
    color: white;
}





.welcome-page-content-box {
    border: 1px solid #bbb;
    border-radius: 1px;
    padding: 10px 10px;
    text-align: center;
    background-color: #fff;
    word-break: break-word;
    display: inline-grid;
}

.welcome-page-content-box.prepare-by {
    min-height: 250px !important
}


.tax-year-header {
    font-size: 22px;
    font-weight: 600;
}


.margin-top-50 {
    margin-top: 30px
}

.margin-top-10 {
    margin-top: 10px
}


.welcome-page-content-box {
    border: 1px solid #bbb;
    border-radius: 1px;
    padding: 10px 10px;
    text-align: center;
    background-color: #fff;
    word-break: break-word;
    display: inline-grid;
}

.welcome-page-content-box.prepare-by {
    min-height: 250px !important
}



.prepared-by-title {
    font-size: 12px;
}

.prepared-by-img {
    max-height: 100px;
    max-width: 100%;
}

.prepared-by-company-name {
    font-size: x-large;
    font-weight: 600;
}


/* cover page css end*/



/*OTP page start*/


.otp-page {
    padding-left: 16% !important;
    padding-right: 16% !important;
    padding-top: 30px !important;
    height: 90vh;
    overflow: auto;
}

.otp-page-container {
    border-radius: 6px;
    padding: 12% 10%;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
    margin-top: 8vh;
}

.otp-page-container input {
    border: 1px solid #8bc656;
    border-radius: 5px;
    background-color: #f3f9ee;
    width: 80px;
    height: 40px;
    color: #000;
    padding: 5px;
    font-weight: bold;
    font-size: 16px;
}

.otp-page-container input {
    width: 160px;
}

.otp-page-container input::-webkit-input-placeholder {
    font-weight: normal;
}

.otp-page-container input::-moz-placeholder {
    font-weight: normal;
}

.otp-page-container input:-ms-input-placeholder {
    font-weight: normal;
}

.otp-page-container input:-o-input-placeholder {
    font-weight: normal;
}

.otp-page-container input[type="radio"] {
    width: 70px;
    height: 20px;
}



/*OTP page end*/


/*SSN  page start*/

.ssn-page {
    padding-left: 16% !important;
    padding-right: 16% !important;
    padding-top: 30px !important;
    height: 90vh;
    overflow: auto;
}

.ssn-page-container {
    border-radius: 6px;
    padding: 12% 10%;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
    height: 100%
}



.ssn-page-container input {
    border: 1px solid #8bc656;
    border-radius: 5px;
    background-color: #f3f9ee;
    width: 80px;
    height: 40px;
    color: #000;
    padding: 5px;
    font-weight: bold;
    font-size: 16px;
}



.ssn-page-container-label-div {
    display: inline-block
}

.ssn-page-container-input-div {
    display: inline-block;
    vertical-align: bottom
}


/*ssn page end*/
.temp-mobile {
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 30px !important;
    height: 90vh;
    overflow: auto;
}

.temp-mobile-container {
    border-radius: 6px;
    padding: 12% 10%;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
    padding-bottom: 75px !important;
}

.temp-mobile-header {
    font-weight: 900;
    font-size: 18px;
    word-wrap: break-word;
}

.temp-mobile-desc {
    margin-top: 100px;
    font-size: 18px;
}

.cursor-disable {
    cursor: not-allowed;
}

.payment-alert {
    background-color: rgb(253, 250, 243);
    border: 1px solid rgb(223, 176, 83);
    font-size: 14px;
    color: #7D5227;
    font-weight: bold;
    font-family: Mulish;
    margin: 0 15px 5px 30px;
}

.payment-alert-icon {
    vertical-align: text-bottom;
    display: inline-block;
    padding-right: 10px;
}

.download-button-padding {
    padding-top: 15px;
    padding-left: 200px;
}

.sign-button-padding {
    padding-top: 15px;
    padding-right: 150px;
}

@media (max-width: 786px) {
    #delegatee .modal-content {
        max-width: 650px;
    }

    .payment-alert {
        margin: 0 15px 5px 15px;
    }

    .download-button-padding {
        padding-left: 15px;
    }

    .sign-button-padding {
        padding-right: 15px;
    }
}

.margin-bottom-0 {
    margin-bottom: 0px !important;
}

.modal-footer .btn {
    min-width: 100px !important;
}